import axios from 'axios'

// let baseUrl = process.env.VUE_APP_API_BASE_URL == null ? 'https://api-staging.boxigo.com' : process.env.VUE_APP_API_BASE_URL
let baseUrl = process.env.VUE_APP_API_URL

let Api = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    common: {        // can be common or any other method
      Accept: 'application/json'
    }
  }
})

export default Api
