<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">
    
    <div class="text-gray-900 text-xl">Add Recipes to {{ mealPlan.name }}</div>
    <div class="bg-sky-500 w-full h-1"></div>

    
    <div class="pt-5 pb-5">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          <input v-model="query" @keyup.enter="searchRecipes()" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ChefPaw Recipes..." required>
          <button v-if="!searching" @click="searchRecipes()" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
          <button v-if="searching" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <div class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
      </div>
    </div>

    <div v-if="recipes.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Recipe name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ recipe.name }}
                      </th>
                      <td class="px-6 py-4">
                        <div v-if="!recipe.loading">
                          <a v-if="!recipe.added" @click="addRecipe(recipe)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Add</a>
                          <span v-if="recipe.added" class="text-green">Added</span>
                        </div>
                        <div v-if="recipe.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="text-gray-900 text-xl">{{ mealPlan.name }} MealPlan</div>
    <div class="bg-sky-500 w-full h-1"></div>

    <div v-if="mealPlan.recipes.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Recipe Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(recipe, index) in mealPlan.recipes" :key="mealPlanRecipeKey(mealPlan, recipe)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ recipe.name }}
                      </th>
                      <td class="px-6 py-4">
                        <div v-if="!recipe.loading">
                          <a @click="removeRecipeAtIndex(index)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Remove</a>
                        </div>
                        <div v-if="recipe.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import axios from 'axios'

  const route = useRoute()

  let searching = ref(false)
  let mealPlan = ref({recipes: []})
  let query = ref(null)
  let recipes = ref([])
  let errors = ref([])

  onMounted(async () => {
      await getPlan()
  })

  const getPlan = async () => {
    try {
      let res = await axios.get('meal-plans/' + route.params.id)
      mealPlan.value = res.data.meal_plan
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const searchRecipes = async () => {
    searching.value = true
    try {
      let res = await axios.get('recipes?query=' + query.value)
      console.log(res.data)
      recipes.value = res.data.recipes
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const addRecipe = async (recipe) => {
    let data = new FormData();
    data.append('recipes', JSON.stringify([{id: recipe.id}]))

    try {
      let res = await axios.post('meal-plans/' + mealPlan.value.id + '/add-recipes', data)
      mealPlan.value = res.data.meal_plan
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const editMealPlan = async () => {
    let data = new FormData();
    data.append('meal_plan', JSON.stringify(mealPlan.value))

    try {
      let res = await axios.post('meal-plans/' + mealPlan.value.id + '?_method=PUT', data)
      mealPlan.value = res.data.meal_plan
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const removeRecipeAtIndex = async (index) => {
    mealPlan.value.recipes.splice(index, 1)
    editMealPlan()
  }

  const mealPlanRecipeKey = (mealPlan, recipe)  => {
    return mealPlan.id+'-'+recipe.id
  }
</script>
