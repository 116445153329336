import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import './styles/app.css'; // Here

// import UserService from './services/user.service.js'
// import { useUserStore } from './store/user-store.js'

startApp()

async function startApp () {
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)

  const app = createApp(App)

  app.use(router)
  app.use(pinia)

  // const userStore = useUserStore()

  // attempt to auto refresh token before startup
  // try {
  //   let res = await UserService.getMe()
  //   userStore.setUserDetails(res)
  // } catch {
  //   userStore.clearUser()
  // }

  app.mount('#app')
}
