<template>
  <div>
      <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2 
          "
          :class="[labelColor ? 'text-gray-900' : 'text-gray-100']"
      >
          {{ label }}
      </label>
      <select class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border
              border-gray-400
              rounded
              py-3
              px-4
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-gray-500
              "
              v-model="selectionComputed"
      >
        <option v-for="(option, index) in options" :key="index" :selected="option == selectionComputed">{{ option }}</option>
      </select>
      <span v-if="error" class="text-red-500">
          {{ error }}
      </span>
  </div>          
</template>

<script setup>
    import { defineProps, defineEmits, toRefs, computed } from 'vue'
    const emit = defineEmits(['update:selection'])
    const props = defineProps({
        label: String,
        labelColor: { type: Boolean, default: true },
        selection: String,
        options: Array,
        error: String
    })
    const { label, labelColor, selection, options, error } = toRefs(props)
    const selectionComputed = computed({
        get: () => selection.value,
        set: (val) => emit('update:selection', val)
    })
</script>
