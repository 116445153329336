<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-xl">{{ recipe.name }} Recipe Nutrition</div>
    <div class="bg-sky-500 w-full h-1"></div>

    <div v-if="recipe.ingredients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          {{ recipe.name }} Recipe
                      </th>
                      <th scope="col" class="px-6 py-3" v-for="ingredient in recipe.ingredients" :key="ingredientHeaderKey(ingredient)">
                          {{ ingredient.name }}
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">100% of recipe</th>
                    <td class="px-6 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientPercentKey(ingredient)">
                      {{ ingredient.amount.percent_of_recipe }}% of recipe
                    </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ recipe.nutrition_breakdown.total_qty }}{{ recipe.nutrition_breakdown.total_unit_type }}
                    </th>
                    <td class="px-6 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientTotalKey(ingredient)">
                      {{ ingredient.nutrition_breakdown.total_qty }}{{ ingredient.nutrition_breakdown.total_unit_type }}
                    </td>
                  </tr>
                  <tr v-for="(nutrient, index) in recipe.nutrition_breakdown.nutrients" :key="recipeNutrientKey(recipe, nutrient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }} {{ nutrient.amount.qty }}{{ nutrient.amount.unit_type }}
                      </th>
                      <td class="px-6 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                        {{ nutrient.name }} {{ ingredient.nutrition_breakdown.nutrients[index].amount.qty }}{{ ingredient.nutrition_breakdown.nutrients[index].amount.unit_type }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import RecipeService from '../../services/recipe.service'

  const route = useRoute()

  let recipe = ref({ingredients: []})
  let errors = ref([])

  onMounted(async () => {
      await getRecipe()
  })

  const getRecipe = async () => {
    try {
      let res = await RecipeService.getRecipeWithNutrition(route.params.id)
      recipe.value = res.data.recipe
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const ingredientHeaderKey = (ingredient)  => {
    return 'head-'+ingredient.id
  }

  const ingredientPercentKey = (ingredient)  => {
    return 'percent-'+ingredient.id
  }

  const ingredientTotalKey = (ingredient)  => {
    return 'total-'+ingredient.id
  }
  
  const ingredientBodyKey = (ingredient)  => {
    return 'body-'+ingredient.id
  }

  const recipeNutrientKey = (recipe, nutrient)  => {
    return recipe.id+'-'+nutrient.id
  }
</script>
