<template>
  <div class="w-full bg-innogreen text-innocream">
    <div class="
      container
      mx-auto
      max-w-4xl
      h-full
      flex
      flex-wrap
      justify-between
      items-start
      text-xm
      p-6
      pt-8
      pb-4  
    ">
      <div class="mb-4">
        <div class="mb-4">
          Quick Links
        </div>
        <router-link to="/" class="block mb-2">Home</router-link>
      </div>
      <div class="mb-4">
        <div class="mb-4">
          About our company
        </div>
        <p class="mb-4 leading-normal">
          123 Main Street<br>
          Los Angeles, CA<br>
        </p>
        <p class="text-sm">555-555-5555</p>
      </div>
    </div>
    <div class="
      container
      mx-auto
      max-w-4xl
      h-full
      flex
      flex-wrap
      justify-between
      items-center
      text-xm
      py-6
    ">
      &copy;2023 ChefPaw. All rights reserved.
      <div class="pt-4 md:p-0 text-center md:text-right text-xss">
        <div class="no-underline hover:underline">Privacy Policy</div>
        <div class="no-underline hover:underline ml-4">Terms &amp; Conditions</div>
        <div class="no-underline hover:underline ml-4">Contact Us</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  
</script>