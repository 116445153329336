<template>
  <div>
    <button @click="open = !open"
      class="
      bg-transparent
      hover:bg-innoorange-hover
      text-white
      font-semibold
      hover:text-white
      py-1 px-2
      hover:border-transparent
      rounded">
      <i class="fa-solid fa-bars fa-xl"></i>
    </button>

    <div v-show="open"
      class="animated slideInDown faster fixed w-full h-full top-0 left-0 flex justify-center backdrop-blur-sm">
      <div class="bg-black absolute w-full h-full opacity-75">

      </div>
      <div class="my-auto fixed border-white w-80 pt-16">
        <p class="text-2xl text-center text-white font-bold">Menu</p>
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Home"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/home')" />
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Profile"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/profile')" />
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Ingredients"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/ingredients')" />
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Recipes"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/recipes')" />
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Import USDA Ingredients"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/add-usda-ingredient')" />
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Create Recipe"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/create-recipe')" />
        <RouterLinkButton
          v-if="userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Create Meal Plan"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/account/create-meal-plan')" />
        <RouterLinkButton
          v-if="!userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Login"
          color="innoorange"
          colorHover="innoorange-hover"
          @click="handleNavClick('/login')" />
        <RouterLinkButton
          v-if="!userStore.id"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="Register"
          color="innoorange"
          colorHover="innoorange-hover"
          url="/register" />
        <RouterLinkButton
          v-if="userStore.id"
          @click="logout"
          class="w-full text-gray-100 text-center text-lg mt-2"
          btnText="logout"
          color="innoorange"
          colorHover="innoorange-hover" />
        <RouterLinkButton
          @click="open = !open"
          class="w-full text-gray-100 text-center text-lg mt-4"
          btnText="Close"
          color="innoorange"
          colorHover="innoorange-hover" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import RouterLinkButton from '../global/RouterLinkButton.vue'
  import { useUserStore } from '@/store/user-store'
  import UserService from '@/services/user.service'
  import { useRouter } from 'vue-router'
  const userStore = useUserStore()
  const router = useRouter()

  let open = ref(false)

  const logout = async () => {
    try {
      let res = await UserService.logout()
      console.log(res.data)
      userStore.clearUser()
      router.push('/')
    } catch (err) {
      console.log(err)
    }
  }

  const handleNavClick = async (path) => {
    open.value = false
    router.push(path)
  }
</script>
