<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-xl">{{ ingredient.name }} Nutrition</div>
    <div class="bg-sky-500 w-full h-1"></div>

    <div v-if="ingredient.nutrition_breakdown.nutrients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Nutrient
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Amount
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Total</th>
                    <td class="px-6 py-4">
                      {{ ingredient.nutrition_breakdown.total_qty }}{{ ingredient.nutrition_breakdown.total_unit_type }}
                    </td>
                  </tr>
                  <tr v-for="(nutrient, index) in ingredient.nutrition_breakdown.nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.qty }}{{ nutrient.amount.unit_type }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import IngredientService from '../../services/ingredient.service'

  const route = useRoute()

  let ingredient = ref({nutrition_breakdown: {nutrients: []}})
  let errors = ref([])

  onMounted(async () => {
      await getIngredient()
  })

  const getIngredient = async () => {
    try {
      let res = await IngredientService.getIngredient(route.params.id)
      ingredient.value = res.data.ingredient
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }
</script>
