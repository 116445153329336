<template>
    <router-link class="
        float-right
        text-gray-900
        font-semibold
        hover:text-white
        py-2
        px-4
        rounded
    "
        :class="btnClasses"
        :to="url">
        {{ btnText }}
    </router-link>      
</template>

<script setup>
    import { defineProps, toRefs, computed } from 'vue'

    const props = defineProps({
        btnText: String,
        url: { type: String, default: '' },
        color: String,
        colorHover: String
    })
    const { btnText, url, color, colorHover } = toRefs(props)

    // const classes = () => {
    //     let str = ''
    //     if (color.value) str += 'bg-' + color.value
    //     if (colorHover.value) str += 'hover:bg-' + colorHover.value
    //     return str
    // }

    const btnClasses = computed({
        get: () => {
            let str = ''
            if (color.value) str += 'bg-' + color.value
            if (colorHover.value) str += ' hover:bg-' + colorHover.value
            return str
        }
    })
</script>
