import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user-store'
import HomeView from '../views/HomeView.vue'
import TrackingView from '../views/TrackingView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import AccountView from '../views/AccountView.vue'
import AccountHome from '../views/account/AccountHome.vue'
import ProfileSection from '../views/account/ProfileSection.vue'
import EditProfile from '../views/account/EditProfile.vue'
import AddUsdaIngredient from '../views/account/AddUsdaIngredient.vue'
import CreateRecipe from '../views/account/CreateRecipe.vue'
import EditRecipe from '../views/account/EditRecipe.vue'
import CreateMealPlan from '../views/account/CreateMealPlan.vue'
import EditMealPlan from '../views/account/EditMealPlan.vue'
import RecipeNutrition from '../views/account/RecipeNutrition.vue'
import MealPlanNutrition from '../views/account/MealPlanNutrition.vue'
import IngredientNutrition from '../views/account/IngredientNutrition.vue'
import ListIngredients from '../views/account/ListIngredients.vue'
import ListRecipes from '../views/account/ListRecipes.vue'
import ListPets from '../views/account/ListPets.vue'
import AddPet from '../views/account/AddPet.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: TrackingView
  },
  {
    path: '/register',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/profile') : next()
    },
    name: 'register',
    component: RegisterView
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/profile') : next()
    },
    name: 'login',
    component: LoginView
  },
  {
    path: '/account',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next() : next('/login')
    },
    component: AccountView,
    children: [
      {
        path: 'home',
        name: 'AccountHome',
        component: AccountHome
      },
      {
        path: 'profile',
        name: 'ProfileSection',
        component: ProfileSection
      },
      {
        path: 'edit-profile',
        name: 'EditProfile',
        component: EditProfile
      },
      {
        path: 'add-usda-ingredient',
        name: 'AddUsdaIngredient',
        component: AddUsdaIngredient
      },
      {
        path: 'create-recipe',
        name: 'CreateRecipe',
        component: CreateRecipe
      },
      {
        path: 'edit-recipe/:id',
        name: 'EditRecipe',
        component: EditRecipe
      },
      {
        path: 'create-meal-plan',
        name: 'CreateMailPlan',
        component: CreateMealPlan
      },
      {
        path: 'edit-meal-plan/:id',
        name: 'EditMealPlan',
        component: EditMealPlan
      },
      {
        path: 'recipe-nutrition/:id',
        name: 'RecipeNutrition',
        component: RecipeNutrition
      },
      {
        path: 'meal-plan-nutrition/:id',
        name: 'MealPlanNutrition',
        component: MealPlanNutrition
      },
      {
        path: 'ingredients',
        name: 'ListIngredients',
        component: ListIngredients
      },
      {
        path: 'recipes',
        name: 'ListRecipes',
        component: ListRecipes
      },
      {
        path: 'ingredient-nutrition/:id',
        name: 'IngredientNutrition',
        component: IngredientNutrition
      },
      {
        path: 'pets',
        name: 'ListPets',
        component: ListPets
      },
      {
        path: 'add-pet',
        name: 'AddPet',
        component: AddPet
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
