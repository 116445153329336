<template>
  <div class="
      w-full
      sm:w-1/2
      font-bold
      text-gray-100
      mt-4
      rounded
      text-center
      bg-sky-500
      p-2
      py-2
    ">
    Number of recipes 2
  </div>
</template>