import Api from '../apis/api'

class IngredientService {
  searchIngredientsByName (query) {
    return Api.get('api/ingredients?query=' + query)
  }

  getIngredient (id) {
    return Api.get('api/ingredients/' + id)
  }

  listIngredients () {
    return Api.get('api/ingredients')
  }

  destroyIngredient (id) {
    return id
  }
}

export default new IngredientService()
