import Api from '../apis/api'

class PetService {
  listPets () {
    return Api.get('api/pets')
  }
  
  addPet (data) {
    return Api.post('api/pets', data)
  }
}

export default new PetService()
