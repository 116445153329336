<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">
    
    <div class="text-gray-900 text-xl">Recipes</div>
    <div class="bg-sky-500 w-full h-1"></div>

    <div v-if="recipes.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Recipe name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Options
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ recipe.name }}
                      </th>
                      <td class="px-6 py-4">
                        <div v-if="!recipe.loading">
                          <a href="javascript:void(0)" @click="destroyRecipe(recipe)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Destroy</a>
                        </div>
                        <div v-if="recipe.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Destroying...</span>
                        </div>

                        <div><a href="javascript:void(0)" @click="editRecipe(recipe)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a></div>
                        <div><a href="javascript:void(0)" @click="goToRecipe(recipe)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Nutrition</a></div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service'

  const router = useRouter()

  let searching = ref(false)
  let recipes = ref([])
  let errors = ref([])

  onMounted(async () => {
      await getRecipes()
  })

  const getRecipes = async () => {
    searching.value = true
    try {
      let res = await RecipeService.listRecipes()
      recipes.value = res.data.recipes
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const destroyRecipe = async (recipe) => {
    return recipe.id
    // ingredient.loading = true
    // try {
    //   await IngredientService.destroyIngredient(ingredient.id)
    //   searchIngredients()
    // } catch (err) {
    //   errors.value = err.response.data.errors
    //   searching.value = false
    // }
  }

  const goToRecipe = (recipe) => {
    router.push({ name: 'RecipeNutrition', params: { id: recipe.id } })
  }

  const editRecipe = (recipe) => {
    router.push({ name: 'EditRecipe', params: { id: recipe.id } })
  }
</script>
