<template>
  <div id="HomeView" class="container max-w-4xl mx-auto pt-10 pb-20 px6">
    <div class="text-center">
      <router-link to="/" class="text-blue-500 no-underline hover:underline">
        Scan Out
      </router-link>
    </div>
    <div v-if="notice != null" class="text-green-700 text-center">{{ notice }}</div>
    <div v-if="error != null" class="text-red-700 text-center">{{ error }}</div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <TextInput 
            label="Tracking Num"
            id="tracking-num"
            placeholder="12345"
            v-model:input="trackingNum"
            inputType="text"
            @keyup.enter="searchOrders()"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <SubmitFormButton 
            btnText="Search"
            @click="searchOrders()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  // import Swal from 'sweetalert2'
  import TextInput from '@/components/global/TextInput.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'

  let trackingNum = ref(null)
  let orders = ref([])
  let notice = ref(null)
  let error = ref(null)

  onMounted(async () => {
      // do nothing
  })

  const searchOrders = async () => {
    try {
      notice.value = null
      error.value = null
      let res = await axios.get(process.env.VUE_APP_API_URL + 'api/v1/orders_with_tracking_num/' + trackingNum.value)
      orders.value = res.data
      if (orders.value.length > 0) {
        notice.value = "At least 1 order was scanned with tracking number " + trackingNum.value
      } else {
        error.value = "No orders were scanned with tracking number " + trackingNum.value
      }
      trackingNum.value = null
    } catch (err) {
      // nothing
    }
  }
</script>
