import Api from '../apis/api'

class RecipeService {
  createRecipe (data) {
    return Api.post('api/recipes', data)
  }

  getRecipe (id) {
    return Api.get('api/recipes/' + id)
  }

  getRecipeWithNutrition (id) {
    return Api.get('api/recipes/' + id + '?include_all_nutrition_breakdowns=true')
  }

  listRecipes () {
    return Api.get('api/recipes')
  }

  updateRecipe (recipeId, data) {
    return Api.post('api/recipes/' + recipeId + '?_method=PUT', data)
  }

  addIngredients (recipeId, data) {
    return Api.post('api/recipes/' + recipeId + '/add_ingredients', data)
  }
}

export default new RecipeService()
