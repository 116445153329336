import Api from '../apis/api'
import CsrfApi from '../apis/csrf.api'

class UserService {
  async login (credentials) {
    await CsrfApi.getCookie()
    return Api.post('/login', credentials)
  }

  logout () {
    return Api.post('/logout')
  }

  getMe () {
    return Api.get('/api/users/me')
  }
}

export default new UserService()
