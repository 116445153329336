<template>
  <div id="HomeView" class="container max-w-4xl mx-auto pt-10 pb-20 px6">
    <div class="text-center">
      <router-link to="/tracking" class="text-blue-500 no-underline hover:underline">
        Scan Tracking Number
      </router-link>
    </div>
    <div v-if="notice != null" class="text-red-700 text-center">{{ notice }}</div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            Store
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="selStoreId"
        >
          <option v-for="(store, index) in stores" :key="index" :selected="store.storeId == selStoreId" :value="store.storeId">{{ store.storeName }}</option>
        </select>
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Order Num"
            id="order-num"
            placeholder="12345"
            v-model:input="orderNum"
            inputType="text"
            @keyup.enter="screenThenSearchOrders()"
        />
      </div>
    </div>

    <div v-if="order.items.length > 0">
      <!-- <div v-for="(item, index) in items" :key="item.key"> -->
        <div class="flex flex-wrap mt-4 mb-6" v-for="(item, index) in items" :key="item.key">
          <div class="w-full md:w-1/2 px-3">
            {{ '(' + (index+1) + ' of ' + items.length + ') ' + item.name }}
          </div>
          <div class="w-full md:w-1/2 px-3">
            <TextInput 
                label="FDA UDI Code"
                placeholder="12345"
                :id="item.key"
                v-model:input="item.udiCode"
                inputType="text"
                @keyup.enter="enterCode(index)"
            />
          </div>
        </div>
      <!-- </div> -->

      <div class="flex flex-wrap mt-8 mb-6">
        <div class="w-full px-3">
          <SubmitFormButton 
              btnText="Submit"
              @click="submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  import Swal from 'sweetalert2'
  import TextInput from '@/components/global/TextInput.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'

  let stores = ref([])
  let selStoreId = ref(396829) // kegg
  let orderNum = ref(null)
  let order = ref({items: []})
  let items = ref([])
  let notice = ref(null)

  onMounted(async () => {
      await getStores()
  })

  const getStores = async () => {
    try {
      let res = await axios.get(process.env.VUE_APP_API_URL + 'api/v1/stores')
      stores.value = res.data
    } catch (err) {
      // nothing
    }
  }

  const screenThenSearchOrders = async () => {
    try {
      let res = await axios.get(process.env.VUE_APP_API_URL + 'api/v1/outbound_orders_by_order_num/' + orderNum.value)
      let prevOrders = res.data
      if (prevOrders.length > 0) {
        let LastOrderName = prevOrders[0].ship_to_name
        let msg = 'An order with order num ' + orderNum.value + ' has been saved before'
        if (LastOrderName) {
          msg += ' (' + LastOrderName + ')'
        }
        msg += '. Among other reasons, this can happen when a different kegg store has had the same order num.'
        Swal.fire({
          title: 'Continue?',
          text: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, continue!'
        }).then((result) => {
          if (result.isConfirmed) {
            searchOrders()
          }
        })
      } else {
        searchOrders()
      }
    } catch (err) {
      // nothing
    }
  }

  const searchOrders = async () => {
    items.value = []
    try {
      let res = await axios.post(process.env.VUE_APP_API_URL + 'api/v1/order_search', {
        orderId: orderNum.value,
        storeId: selStoreId.value
      })
      order.value = res.data
      for (let i = 0; i < order.value.items.length; i++) {
        for (let j = 0; j < order.value.items[i].quantity; j++) {
          let item = structuredClone(order.value.items[i])
          item.key = item.orderItemId + '-' + j
          item.udiCode = ''
          item.authCode = ''
          items.value.push(item)
        }
      }
      if (items.value.length > 0) {
        setTimeout(() => {
          document.getElementById(items.value[0].key).focus()
        }, "500")
      }
      console.log('order', order.value)
    } catch (err) {
      // nothing
    }
  }

  const submit = async () => {
    for (let i = 0; i < items.value.length; i++) {
      let item = items.value[i]
      if (!/^\d{6}$/.test(item.udiCode)) {
        Swal.fire('Error', 'Item ' + (i+1) + ' (' + item.udiCode + ') is not the proper format of 6 digits!', 'error')
        return
      }
    }
    try {
      let uidCodes = []
      for (var i = 0; i < items.value.length; i++) {
        uidCodes.push(items.value[i].udiCode)
      }
      let res = await axios.post(process.env.VUE_APP_API_URL + 'api/v1/oders_with_uid_codes', {
        uid_codes: uidCodes
      })
      let prevOrders = res.data
      if (prevOrders.length) {
        let lastOrderNum = prevOrders[0].order_id
        let LastOrderName = prevOrders[0].ship_to_name
        let msg = 'One or more of these codes have been saved before. The last associated order is ' + lastOrderNum
        if (LastOrderName) {
          msg += ' (' + LastOrderName + ')'
        }
        Swal.fire({
          title: 'Save this code again?',
          text: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, save it!'
        }).then((result) => {
          if (result.isConfirmed) {
            saveCodes()
          }
        })
      } else { // none of the codes have been saved before
        saveCodes()
      }
    } catch (err) {
      Swal.fire('Error', err.response.data.message, 'error')
    }
  }

  const saveCodes = async () => {
    try {
      await axios.post(process.env.VUE_APP_API_URL + 'api/v1/scan-out', {
        orderId: orderNum.value,
        items: items.value
      })
      notice.value = 'last successful submitted order: ' + orderNum.value
      orderNum.value = null
      order.value = {items: []}
      items.value = []
      document.getElementById('order-num').focus()
    } catch (err) {
      Swal.fire('Error', err.response.data.message, 'error')
    }
  }

  const enterCode = (itemIndex) => {
    document.getElementById(items.value[itemIndex+1].key).focus();
  }
</script>
