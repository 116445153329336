<template>
  <div class="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
    
    <!--Main Col-->
    <div id="profile" class="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-innocream mx-6 lg:mx-0">
    
  
      <div class="p-4 md:p-12 text-center lg:text-left">
        <!-- Image for mobile view-->
        <div class="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center" :style="{ backgroundImage: 'url(' + require('@/assets/img/profile_dog_sample.jpg') + ')' }"></div>
        
        <h1 class="text-3xl font-bold pt-8 lg:pt-0">{{ pet.name }}</h1>
        <div class="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-innobrown"></div>
        <p class="pt-4 text-base font-bold flex items-center justify-center lg:justify-start"><i class="fa-solid fa-weight-scale fa-xl text-innogreen pr-4"></i> {{ kgToLbs(pet.weight_kg) }} lbs</p>
        <p class="pt-4 text-base font-bold flex items-center justify-center lg:justify-start"><i class="fa-solid fa-calendar fa-xl text-innogreen pr-4"></i> Born {{ formatDate(pet.birthday) }}</p>
        <p class="pt-4 text-base font-bold flex items-center justify-center lg:justify-start"><i class="fa-solid fa-bowl-rice fa-xl text-innogreen pr-4"></i> 1000 Cal/Day</p>
  
        <div class="pt-12 pb-8">
          <button class="bg-innoorange hover:bg-innoorange-hover text-white font-bold py-2 px-4 rounded-full">
            Edit
          </button> 
        </div>

        
        <!-- Use https://simpleicons.org/ to find the svg for your preferred product --> 
  
      </div>
  
    </div>
    
    <!--Img Col-->
    <div class="w-full lg:w-2/5">
      <!-- Big profile image for side bar (desktop) -->
      <img src="@/assets/img/profile_dog_sample.jpg" class="rounded-none lg:rounded-lg shadow-2xl hidden lg:block">
      <!-- Image from: http://unsplash.com/photos/MP0IUfwrn0A -->
      
    </div>
    
  
  </div>
</template>

<script setup>
    import { defineProps, toRefs } from 'vue'

    const props = defineProps({
        pet: Object
    })
    const { pet } = toRefs(props)

    const kgToLbs = (kg) => {
      return Math.round((kg / 0.45359237) * 100) / 100
    }

    const formatDate = (yyyMmDd) => {
      let date = Date.parse(yyyMmDd)
      return new Date(date).toDateString()
    }
</script>