<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-10 pb-20 px6">
    
    <div class="grid place-items-center">
      <img src="@/assets/chef_paw_logo_1.jpg" class="h-40">
      <div class="p-4 w-2/3">
        <div class="flex rounded-lg h-full bg-innocream p-8 flex-col hover:bg-innobrown cursor-pointer" @click="goListPets()">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-dog w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Pet Profiles</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">View &amp; create profiles for your pets that can be used to customize Recipes and Advanced Meal Plans.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-2/3">
        <div class="flex rounded-lg h-full bg-innocream p-8 flex-col hover:bg-innobrown cursor-pointer">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-drumstick-bite w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Recipes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">View &amp; create nutritious and money-saving homemade Recipes for your pet.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-2/3">
        <div class="flex rounded-lg h-full bg-innocream p-8 flex-col hover:bg-innobrown cursor-pointer">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-blender w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Make A Batch</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Ready to get cookin'? ChefPaw will walk you through the process of turning a Recipe into your own batch of ready-to-serve meals.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-2/3">
        <div class="flex rounded-lg h-full bg-innocream p-8 flex-col hover:bg-innobrown cursor-pointer">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-utensils w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Advanced Meal Plans</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Give your pet a diverse diet and take nutrition to the next level by creating multi-Recipe Advanced Meal Plans.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'

  const router = useRouter()

  const goListPets = () => {
    router.push({ name: 'ListPets' })
  }
</script>
