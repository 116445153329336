<template>
  <div id="ListPets" class="container max-w-4xl mx-auto pt-10 pb-20 px6">

    <div class="text-gray-900 text-xl">My Pets</div>
    <div class="bg-innobrown w-full h-1"></div>
    
    <div v-if="pets.length > 0">
      <PetCard v-for="pet in pets" :pet="pet" :key="pet.id" />
    </div>

    <div class="grid place-items-center">
      <div class="p-4 w-2/3">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="addPet()">
          Add Pet
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import PetService from '../../services/pet.service'
  import PetCard from '@/components/PetCard.vue'

  const router = useRouter()

  let pets = ref([])

  onMounted(async () => {
      await getPets()
  })

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      // error
    }
  }

  const addPet = () => {
    router.push({ name: 'AddPet' })
  }
</script>
