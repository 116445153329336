<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">
    
    <div class="text-gray-900 text-xl">Ingredients</div>
    <div class="bg-sky-500 w-full h-1"></div>

    
    <div class="pt-5 pb-5">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          <input v-model="query" @keyup.enter="searchIngredients" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ChefPaw Ingredients..." required>
          <button v-if="!searching" @click="searchIngredients" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
          <button v-if="searching" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <div class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
      </div>
    </div>

    <div v-if="ingredients.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Ingredient name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Action
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Nutrition
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(ingredient, index) in ingredients" :key="ingredient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ ingredient.name }}
                      </th>
                      <td class="px-6 py-4">
                        <div v-if="!ingredient.loading">
                          <a href="javascript:void(0)" @click="destroyIngredient(ingredient)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Destroy</a>
                        </div>
                        <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Destroying...</span>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <a href="javascript:void(0)" @click="goToIngredient(ingredient)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">View</a>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import IngredientService from '../../services/ingredient.service'

  const router = useRouter()

  let searching = ref(false)
  let query = ref(null)
  let ingredients = ref([])
  let errors = ref([])

  onMounted(async () => {
      await getIngredients()
  })

  const searchIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.searchIngredientsByName(query.value)
      console.log(res.data)
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const getIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.listIngredients()
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const destroyIngredient = async (ingredient) => {
    ingredient.loading = true
    try {
      await IngredientService.destroyIngredient(ingredient.id)
      searchIngredients()
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const goToIngredient = (ingredient) => {
    router.push({ name: 'IngredientNutrition', params: { id: ingredient.id } })
  }
</script>
