<template>
  <div id="EditProfile" class="container max-w-4xl mx-auto pt-20 pb-20 px6">
    
    <div class="text-gray-900 text-xl">Add Pet</div>
    <div class="bg-sky-500 w-full h-1"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Name"
            placeholder="Rover"
            v-model:input="name"
            inputType="text"
            :error="errors.name ? errors.name[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Weight (lbs)"
            placeholder="10"
            v-model:input="weightLbs"
            inputType="text"
            :error="errors.weight_kg ? errors.weight_kg[0] : ''"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Breed"
            placeholder="Basenji"
            v-model:input="breedName"
            inputType="text"
            :error="errors.breed_name ? errors.breed_name[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Sex"
            placeholder="Female"
            v-model:input="sex"
            inputType="text"
            :error="errors.sex ? errors.sex[0] : ''"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Birthday"
            placeholder="36"
            v-model:input="birthday"
            inputType="date"
            :min="earliestBirthday" :max="latestBirthday"
            :error="errors.birthday ? errors.birthday[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <DropdownMenu 
            label="Activity Level"
            :options="activityLevels"
            v-model:selection="activityLevel"
            :error="errors.activity_level_name ? errors.activity_level_name[0] : ''"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <SubmitFormButton 
            btnText="Update Profile"
            @click="submit"
        />
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import TextInput from '@/components/global/TextInput.vue'
  import DropdownMenu from '@/components/global/DropdownMenu.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'
  import PetService from '@/services/pet.service'

  const router = useRouter()

  let birthday = ref(new Date().toISOString().slice(0, 10))
  let earliestBirthday = ref(new Date().toISOString().slice(0, 10))
  let latestBirthday = ref(new Date().toISOString().slice(0, 10))
  let name = ref(null)
  let weightLbs = ref(null)
  let breedName = ref(null)
  let sex = ref(null)
  let activityLevel = ref('Average')
  let activityLevels = ref(['Low', 'Average', 'High Energy'])
  let errors = ref([])

  onMounted(() => {
      init()
  })

  const init = () => {
    birthday.value = yyyyMmDdYearsFromToday(-1)
    earliestBirthday.value = yyyyMmDdYearsFromToday(-30)
    latestBirthday.value = new Date().toISOString().slice(0, 10)
  }

  const yyyyMmDdYearsFromToday = (years) => {
    let today = new Date()
    let xYearsFromToday = today.setFullYear(today.getFullYear() + years)
    return dateToYyyyMmDd(new Date(xYearsFromToday))
  }

  const dateToYyyyMmDd = (date) => {
    return date.toISOString().slice(0, 10)
  }

  const submit = async () => {
      errors.value = []

      let data = new FormData();
      data.append('name', name.value || '')
      data.append('weight_kg', lbsToKg(weightLbs.value) || '')
      data.append('breed_name', breedName.value || '')
      data.append('sex', sex.value || '')
      data.append('birthday', birthday.value || '')
      data.append('activity_level_name', activityLevel.value || '')
      
      try {
        await PetService.addPet(data)
        router.push('/account/pets')
      } catch (err) {
        errors.value = err.response.data.errors
      }
  }

  const lbsToKg = (lbs) => {
    return lbs * 0.45359237
  }

  // const updateUser = async () => {
  //   errors.value = []

  //   let data = new FormData();
  //   // data.append('first_name', firstName.value || '')
  //   // data.append('last_name', lastName.value || '')

  //   // if (imageData) {
  //   //   data.append('image', imageData.file || '')
  //   //   data.append('height', imageData.heigh || '')
  //   //   data.append('width', imageData.width || '')
  //   //   data.append('left', imageData.left || '')
  //   //   data.append('top', imageData.top || '')
  //   // }

  //   try {
  //     await axios.post('users/me?_method=PUT', data)

  //     await userStore.fetchUser()

  //     router.push('/account/profile')
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }
</script>
